// globally accessible namedspaced js functions
// access these as attendee.function() to avoid conflicts with other libs/functions

var attendee = attendee || {

	helpscout_beacon: function (options) {
		$('a.hsbeacon').click(function (e) {
			window.HubSpotConversations.widget.open();;
			return false
		});

	}

};
